import React from "react"

const ToolsCard = ({ tools }) => {
  return (
    <div>
      <div className="">
        <div data-aos="zoom-in-up" className="">
          <div className=" mb-2 text-2xl lg:text-4xl lato  text-center text-gray-800">
            Clean Code
          </div>
          <div className="text-gray-800 text-sm lg:text-base">
            Für die meisten Quellcode-Zeilen gilt, dass sie nur einmal
            geschrieben werden, während der Weiterentwicklung der Software aber
            viele Male gelesen werden. Deshalb sind wir davon überzeugt, dass
            die <b>Lesbarkeit von Code</b> ebenso wichtig ist wie die
            Funktionalität. Nur so bleibt Ihre Software über viele Jahre wartbar
            und erweiterbar. Neben der Lesbarkeit ist eine hohe Abdeckung des Codes mit{" "}
            <b>Unit Tests</b> unabdingbar um spätere Änderungen an der Software
            gefahrlos durchführen zu können. Deshalb arbeiten wir nach den
            Prinzipien von <b>Clean Code</b>.
          </div>
        </div>

        <div className="h-20"></div>
        <div data-aos="zoom-in-up" className="">
          <div className=" mb-2 text-2xl lg:text-4xl lato  text-center text-gray-800">
            Agilität
          </div>
          <div className="text-gray-800 text-sm lg:text-base">
            Als erfahrene <b>Scrum</b>-Teammitglieder und -Product Owner
            bevorzugen wir eine agile Arbeitsweise und somit hohe{" "}
            <b>Transparenz</b>, stetige Optimierung und konstante{" "}
            <b>Kommunikation</b> mit allen Projektbeteiligten. Über mehrere gemeinsame
            Projekte aufgebaute Synergien resultieren hierbei in hohe{" "}
            <b>Effizienz</b> und geringe Reibungsverluste. Wenn Sie zufrieden
            sind, sind wir es auch. Gerne passen wir unsere Arbeitsweise an Ihre
            Prozesse und Methoden an, um eine effiziente und erfolgreiche
            Projektdurchführung zu gewährleisten.
          </div>
        </div>
      </div>
      <div className="h-48"></div>
    </div>
  )
}

export default ToolsCard
