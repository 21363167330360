import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import ToolsContent from "../components/Tools/ToolsContent"
import ToolsHero from "../components/Tools/ToolsHero"
import Back from "../components/Layout/Back"
import Navigation from "../components/Layout/Navigation"

const ToolsPage = () => (
	<Layout>
		<SEO
			title="Philosophie"
			description="Wir orientieren uns an modernen Vorgehensweisen der Softwareentwicklung. Lesbarer und dokumentierter Code, agile Projektarbeit und konstante Kommunikation zum Kunden sind für uns selbstverständlich"
		/>
		<Back color={"#e9c46a"} />
		<Navigation contact={false} active="tools" />
		<ToolsHero />
		<ToolsContent />
	</Layout>
)

export default ToolsPage
