import React from "react"
import ToolsCard from "./ToolsCard"
import { Element } from "react-scroll"

const ToolsContent = () => {
	const tools = { color: "#2a9d8f" }

	return (
		<div className="">
			<Element name="content" className="element">
				<ToolsCard className="" tools={tools} />
			</Element>
		</div>
	)
}

export default ToolsContent
